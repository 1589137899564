/*
 * src/modules/form/floatting/style.scss
 */
$form_el_height:36px;

@mixin formElInputStyle{
  display:block;
  width:100%; box-sizing:border-box;
  box-shadow:0px 2px 0px $N_COLOR_4;
  height:$form_el_height;
  font-family:"Pretendard"; font-size:18px; font-weight:600; color:white; letter-spacing:-0.5px;
  transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

.form_el{
  position:relative; z-index:0; display:block;
  font-size:0;
  
  + .form_el{
    margin-top:26px;
  }
  
  > article{
    position:relative;
    
    > label{
      display:inline-block;
      color:$N_COLOR_6; font-size:14px;
    }
    
    > .error_msg{
      display:none;
      color:$WARN_COLOR_0; font-size:14px;
    }
    
    > input[type="text"],
    > input[type="password"],
    > input[type="email"],
    > input[type="file"],
    > button,
    > select{
      outline:none; border:none; background:none;
    }
    
    > a, > select, > button{
      cursor:pointer;
    }
    
    > input{
      @include formElInputStyle;
    }
    
    > select{
      //outline:1px dashed red; box-shadow:0px 0px 10px cornflowerblue;
      z-index:0; position:absolute; top:0px; left:0px; width:100%; height:100%;
      background-color:$N_COLOR_2; color:white; outline:none;
      //opacity:0.5;
      opacity:0; pointer-events:none;
      
      > option{
        border:none;
        outline:none;
        cursor:pointer;
        padding:20px;
        background-color:$N_COLOR_2; color:white;
        font-size:18px;
        display:none;
      }
    }
    
    > .custom_select{
      @include formElInputStyle;
      position:relative; z-index:10; user-select:none;
      
      > .label{
        display:flex; align-items:center; justify-content:left;
        width:100%; height:$form_el_height;
        cursor:pointer;
      }
      
      > .label:before{
        transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
        display:inline-block; content:"";
        position:absolute; z-index:3;
        background-image:url(@/assets/svg_icon/qna_down_arrow.svg); background-position:center; background-repeat:no-repeat;
        width:16px; height:11px; top:50%; transform:translateY(-50%) rotate(0deg); right:15px;
      }
      
      > .options{
        transition:all 0ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
        overflow:hidden; max-height:0; padding-top:0; padding-bottom:0;
        background-color:$N_COLOR_2;
        padding-left:20px; padding-right:20px;
        z-index:10; position:absolute; bottom:-3px; transform:translateY(100%); left:0px;
        width:100%;
        
        > li{
          font-size:18px; color:white; letter-spacing:-0.5px; line-height:28px;
          cursor:pointer;
        }
        
        > li + li{
          margin-top:20px;
        }
      }
      
    }
    
    > .custom_file_input{
      @include formElInputStyle;
      
      > .label{
        display:flex; align-items:center; justify-content:left;
        width:100%; height:$form_el_height;
        cursor:pointer;
        
        > .placeholder{
        
        }
        
        > .filename{
          color:$N_COLOR_6;
        }
      }
      
    }
    
    > input[type="file"]{
      z-index:0; position:absolute; top:0px; left:0px;
      width:100%; height:100%;
      opacity:0;
      //opacity:0.2; background-color:yellow;
    }
    
    > .reset_btn{
      transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
      position:absolute; z-index:10; cursor:pointer;
      background-image:url(@/assets/svg_icon/icon_file_input.svg); background-size:14px; background-position:center; background-repeat:no-repeat;
      width:$form_el_height; height:100%; top:50%; transform:translateY(-50%) rotate(0deg); right:16px;
    }
    
    > .reset_btn.active{
      transform:translateY(-50%) rotate(45deg);
    }
    
    > textarea{
      position:absolute; top:0; left:0; z-index:0; pointer-events:none; opacity:0;
      margin-top:10px;
      width:100%; height:100%; overflow:hidden; resize:none;
    }
    > .textarea_div_wrap{
      @include formElInputStyle;
      height:auto;
      display:flex; align-items:flex-end;
      
      > .textarea_div{
        flex-grow:1;
        outline:none; min-height:31px;
        font-size:18px; line-height:31px; color:white;
        width:calc(100% - 70px);
      }
      
      > .len_box{
        min-width:70px; text-align:right;
        color:$N_COLOR_6; font-family: "Poppins", sans-serif;font-style:normal;font-weight:400;font-size:14px;line-height:24px;
        text-align:right; white-space:nowrap;
        
      }
    }
    
    > .bottom_bar{
      z-index:20; position:absolute; bottom:-3px; left:0px;
      transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
      width:0%; height:3px;
      background-color:white;
    }
    
  }
  
}

.form_el.focused{
  z-index:10;
  
  > article{
    > .custom_select{
      
      > .options{
        
        transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
        max-height:100vh;
        padding-top:30px; padding-bottom:30px;
      }
      
      > .label:before{
        transform:translateY(-50%) rotate(180deg);
      }
    }
    
    > .custom_file_input{
    
    }
    
  }
}

.form_el.is_error,
.form_el.focused{
  > article{
    > .bottom_bar{
      width:100%;
    }
  }
}

.form_el.is_error{
  > article{
    > label{
      display:none;
    }
    
    > .error_msg{
      display:inline-block;
    }
    
    > .bottom_bar{
      background-color:$WARN_COLOR_0;
    }
  }
}

.CompCheckBox.is_error{
  > label{
    > .label{
      transition: all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
      color:$WARN_COLOR_0;
    }
  }
}





