/*
 * src/assets/scss/main.scss
 */
#page.LayoutDefault[data-name="메인"]:not(.fill_header){
  
  header{
    background-color:rgba(17, 17, 17, 0);
  }
}

#page.LayoutDefault[data-name="메인"]{
  > main{
    width:100%;
    padding-bottom:0;
  }
  
  section + section{ margin-top:180px; }
  
  section.visualsub_sec{
    /*margin-top:259px;*/
    margin-top:15.6vw;
  }
  
  .news_sec{
    @include width_section;
    
    .tmb_list_ui.news{
      row-gap:110px;
    }
  }
  
  .atc_about{
    padding-bottom:0;
  }
  
  .atc_about:before{
    display:none;
  }
  
  & ~ .goto_career_btn{
    //animation:1.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 5.1s 1 normal forwards running animation_visual_txt1;
    //transform:translateX(140%);
  
    animation:5.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.9s 1 normal forwards running animation_visual_txt2;
  }
  
}

@media screen and (min-width:#{$MAX_WIDTH}){
  #page.LayoutDefault[data-name="메인"]{
    section.visualsub_sec{
      //margin-top:193px;
      margin-top:300px;
    }
  }
}

@media screen and (max-width:#{$MIN_WIDTH}){
  #page.LayoutDefault[data-name="메인"]{
    section.visualsub_sec{
      margin-top:200px;
    }
  }
}

.visual_sec{
  @include full_width_section;
  margin-top:-$HEADER_HEI; height:100vh; min-height:$MIN_HEIGHT;
  overflow:hidden;
  
  >.atc_visual{
    position:absolute; z-index:1;
    top:0; left:50%; max-width:1690px; width:100%; height:100vh; min-height:$MIN_HEIGHT;
    transform:translateX(-50%);
    display:flex; flex-direction:column; justify-content:center;
    opacity:0;
    animation:2.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.3s 1 normal forwards running animation_visual_show;
  
    > .visual_0{
      //outline:1px dashed red; box-shadow:0px 0px 10px cornflowerblue;
      //text-shadow:-1px 0 $N_COLOR_8, 0 1px $N_COLOR_8, 1px 0 $N_COLOR_8, 0 -1px $N_COLOR_8;
      color:$N_COLOR_1; font-family: "Poppins", sans-serif; font-weight:700; letter-spacing:2px;
      /*font-size:130px; line-height:134px;*/
      font-size:7.3vw; line-height:1.04em;
      /*padding-top:76px;*/ padding-left:$MAIN_SIDE_PADDING;
  
      -webkit-text-stroke: 1px $N_COLOR_8;
      color:rgba(0,0,0,0);
  
      .n_color_8{ text-shadow:none;}
    
      .plus{
        margin-left:1.2vw;
        /*font-size:148px;*/
        font-size:7.7vw;
      }
  
      > .flex_box{
        display:flex;
        align-items:center;
      }
  
      [data-word-order]{
        animation:0.1s cubic-bezier(0.215, 0.610, 0.355, 1.000) 2.1s 1 normal forwards running animation_visual_txt;
        opacity:0;
        display:inline-block;
      }
      [data-word-order="1"]{ animation-delay:2.1s; }
      [data-word-order="2"]{ animation-delay:2.2s; }
      [data-word-order="3"]{ animation-delay:2.3s; }
      [data-word-order="4"]{ animation-delay:2.4s; }
      [data-word-order="5"]{ animation-delay:2.5s; }
      [data-word-order="6"]{ animation-delay:2.6s; }
      [data-word-order="7"]{ animation-delay:2.7s; }
      [data-word-order="8"]{ animation-delay:2.8s; }
      [data-word-order="9"]{ animation-delay:2.9s; }
      [data-word-order="10"]{ animation-delay:3.0s; }
      [data-word-order="11"]{ animation-delay:3.1s; }
      [data-word-order="12"]{ animation-delay:3.2s; }
      [data-word-order="13"]{ animation-delay:3.3s; }
      [data-word-order="14"]{ animation-delay:3.4s; }
      [data-word-order="15"]{ animation-delay:3.5s; }
      [data-word-order="16"]{ animation-delay:3.6s; }
      [data-word-order="17"]{ animation-delay:3.7s; }
      [data-word-order="18"]{ animation-delay:3.8s; }
      [data-word-order="19"]{ animation-delay:4.0s;
        animation-name:animation_visual_txt_plus;
        animation-timing-function:linear;
        animation-duration:1s;
        transform:rotate(0deg);
      }
    }
  
    > .visual_1{
      display:flex; justify-content:flex-end;
      font-weight:500;
      margin-top:2.3vw;
      /*font-size:20px; line-height:28px;*/
      font-size:1.35vw; line-height:1.3em;
      
      /*margin-top:10.5vh;*/ padding-right:$MAIN_SIDE_PADDING;
    
      > .desc{
        animation:1.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 5.1s 1 normal forwards running animation_visual_txt1;
        transform:translateX(140%);
      }
    }
  }
}

@keyframes animation_visual_show{
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
}

@keyframes animation_visual_txt{
  0%{
    transform:translateX(-5px);
  }
  5%{
    transform:translateX(0px);
  }
  100%{
    transform:translateX(0px);
    opacity:1;
  }
}

@keyframes animation_visual_txt_plus{
  0%{
    opacity:0.0;
    transform:rotate(0deg);
  }
  1%{
    opacity:1;
  }
  100%{
    opacity:1;
    transform:rotate(180deg)
  }
}

@keyframes animation_visual_txt1{
  0%{
    transform:translateX(140%);
  }
  100%{
    transform:translateX(0%);
  }
}

@keyframes animation_visual_txt2{
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
}

@media screen and (min-width:#{$MAX_WIDTH}){
  .visual_sec{
    >.atc_visual{
      > .visual_0{
        font-size:140px; line-height:144px;
        
        .plus{
          margin-left:9.2px;
          /*font-size:122.9px;*/
          font-size:148px;
        }
      }
      
      > .visual_1{
        margin-top:60px; font-size:26px; line-height:34px;
      }
    }
  }
}

@media screen and (max-width:#{$MIN_WIDTH}){
  .visual_sec{
    >.atc_visual{
      > .visual_0{
        font-size:93px; line-height:97px;
        
        .plus{
          margin-left:15px; font-size:98px;
        }
      }
  
      > .visual_1{
        margin-top:29.4px;
        font-size:17.2px; line-height:22.4px;
      }
    }
  }
}

.visualsub_sec{
  //@include width_section;
  padding-left:$MAIN_SIDE_PADDING; padding-right:$MAIN_SIDE_PADDING; width:1280px;
  position:relative;
  left:50%; transform:translateX(-50%);
  
  .atc_visualsub{
    .visual_2{
      display:flex; justify-content:space-between; align-items:center;
      padding-bottom:65px;
      
      > .word_0{
        
        > .eng{
          font-family: "Poppins", sans-serif; font-weight:700; text-align:center; letter-spacing:1px;
          font-size:60px; line-height:73px;
          
          > .plus{
            /*margin-left:5px;*/
            margin-left:1.2vw;
            font-size:80px;
          }
        }
        
        > .eng.flex_box{
          display:flex;
          justify-content:center; align-items:center;
        }
        
        > .kor{
          /*margin-top:17px;*/
          margin-top:30px;
          line-height:26px; text-align:center;
          /* font-weight:600; */
          font-size:26px; line-height:36px;
          color:$N_COLOR_7;
        }
        
        > .flex_box{
          display:flex;
          justify-content:center; align-items:center;
        }
      }
      
      > .word_0 + .word_0{
      }
      
      > .word_1{
        position:absolute; top:-35px; left:50%; width:calc(100% + #{$MAIN_SIDE_PADDING} * 4); overflow:hidden;
        transform:translate(-50%, -50%);
        font-family: "Poppins", sans-serif; font-weight:700; text-align:center; white-space:nowrap; text-indent:-7px;
        /*font-size:72px; line-height:88px;*/
        font-size:254px; line-height:1.22em;
        color:rgba(255, 255, 255, 0.05);
      }
    }
  }
}

[data-trigger].visual_2{
  .word_0:nth-child(1){
    transform:translate3d(0px, 50px, 0px);
    opacity:0;
  }
  
  .word_0:nth-child(2){
    transform:translate3d(0px, 50px, 0px);
    opacity:0;
  }
  
  .word_0:nth-child(3){
    transform:translate3d(0px, 50px, 0px);
    opacity:0;
  }
}

[data-trigger].visible.visual_2{
  
  .word_0:nth-child(1){
    animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.3s 1 normal forwards running animation_scroll_trigger;
    //transform:translate3d(0px, 50px, 0px);
    //opacity:0;
  }
  
  .word_0:nth-child(2){
    animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.8s 1 normal forwards running animation_scroll_trigger;
    //transform:translate3d(0px, 50px, 0px);
    //opacity:0;
  }
  
  .word_0:nth-child(3){
    animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1.3s 1 normal forwards running animation_scroll_trigger;
    //transform:translate3d(0px, 50px, 0px);
    //opacity:0;
  }
  
}

.creator_sec{
  @include width_section;
}

.atc_creator{
  position:relative;
  
  > .more_btn{
    position:absolute; top:-145px; right:0; font-size:0;
    transition:all 1000ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-duration:0.3s;
    width:80px; height:80px;
    background:url("@/assets/svg_icon/icon_more.svg") no-repeat 0 0;
  }
  
  > .more_btn:hover{
    background-image:url("@/assets/svg_icon/icon_more_1.svg");
  }
}

.atc_news{
  position:relative;
  
  .tmb_list_ui.news{
    > .item:nth-child(n+5){ display:none; }
  }
  
  > .more_btn{
    position:absolute; top:-145px; right:0; font-size:0;
    transition:all 1000ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-duration:0.3s;
    width:80px; height:80px;
    background:url("@/assets/svg_icon/icon_more.svg") no-repeat 0 0;
  }
  
  > .more_btn:hover{
    background-image:url("@/assets/svg_icon/icon_more_1.svg");
  }
}

.about_sec{
  @include width_section;
  
  .atc_about{}
}


.atc_our_partner{
  
  margin-top:150px;
  display:flex; justify-content:space-around; align-items:flex-start;
  
  .titui_h2{ margin-top:0; font-size:70px; line-height:80px; }
  
  .desc{
    margin-top:10px; margin-bottom:30px;
    font-weight:500; font-size:20px; line-height:30px;
    color:$N_COLOR_7;
  }
  
  .partner{
    .item[data-trigger].invisible{
      opacity:0;
    }
    .item[data-trigger]:nth-child(odd).visible{
      animation:0.6s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.2s 1 normal forwards running animation_partner;
      opacity:0;
      
    }
    .item[data-trigger]:nth-child(even).visible{
      animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.4s 1 normal forwards running animation_partner;
      opacity:0;
    }
  }
}

@keyframes animation_partner{
  0%{
    opacity:0;
    transform:scale3d(.3, .3, .3);
  }
  100%{
    opacity:1;
    ransform:scale3d(1, 1, 1);
  }
}

.ux_enm_sec{
  padding-top:106px; padding-bottom:106px;
  background-color:$N_COLOR_2;
  @include full_width_section;
  
  .atc_ux_enm{
    
    @include width_section;
    /*margin-bottom:-70px;*/
    /*padding-bottom:70px;*/
    display:flex; justify-content:space-between;
  
    //outline:1px dashed red; box-shadow:0px 0px 10px cornflowerblue;
    
    .address{
      font-weight:500; font-size:20px; line-height:30px;
      color:$N_COLOR_6;
    }

    .contact{
      margin-top:69px;
  
      > li{
        font-weight:500; font-size:20px; line-height:30px;
        color:$N_COLOR_6;
    
        > a[href^=mailto]{
          color:$N_COLOR_6;
        }
    
        .label{
          margin-right:26px; font-weight:600;
        }
      }
  
      > li + li{
        margin-top:8px;
      }
    }
    
    .location{
      font-size:0;
      
      > .tmb{
        font-size:0; line-height:0;
        overflow:hidden;
        
        > img{
          width:100%;
        }
      }
    }
  }
}

[data-trigger].visible.ux_enm_sec{
  .titui_h1{
    img{
      animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.0s 1 normal forwards running animation_scroll_trigger;
      transform:translate3d(0px, 50px, 0px);
      opacity:0;
    }
  }
  
  .atc_ux_enm{
    .address{
      animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.5s 1 normal forwards running animation_scroll_trigger;
      transform:translate3d(0px, 50px, 0px);
      opacity:0;
    }
    
    .contact{
      > li:nth-child(1){
        animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1.0s 1 normal forwards running animation_scroll_trigger;
        transform:translate3d(0px, 50px, 0px);
        opacity:0;
      }
      > li:nth-child(2){
        animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1.2s 1 normal forwards running animation_scroll_trigger;
        transform:translate3d(0px, 50px, 0px);
        opacity:0;
      }
      > li:nth-child(3){
        animation:0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1.4s 1 normal forwards running animation_scroll_trigger;
        transform:translate3d(0px, 50px, 0px);
        opacity:0;
      }
    }
  }
}

.creator_swiper_wrap{
  //@include full_width_section;
  
  margin-left:-370px; margin-right:-370px;
}

.about_swiper_wrap{
  //@include full_width_section;
  margin-left:-370px; margin-right:-370px;
  
  .swiper_ui.SwiperCustom > .pagination_area{
    @include width_section;
  }
}

@keyframes animation_scroll_trigger{
  from{
    opacity:0;
    transform:translate3d(0px, 50px, 0px);
  }
  to{
    opacity:1;
    transform:translate3d(0px, 0px, 0px);
  }
}
