/*
 * src/assets/scss/reset.scss
 * 용도 : 모든 페이지 베이스 CSS
 */
@media print{
  /*배경인쇄*/
  *{ -webkit-print-color-adjust:exact; }
  .noprint{ display:none; }
}
*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
html, input, textarea, select, button, xmp{ font-family: "Pretendard", -apple-system, BlinkMacSystemFont, "Malgun Gothic", "맑은 고딕", helvetica, "Apple SD Gothic Neo", sans-serif; }
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, sup, sub,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video{
  margin:0;
  padding:0;
  border:0;
  word-break:keep-all;
  //letter-spacing:calc(-50 * 0.001em);
  letter-spacing:-0.5px;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, form{
  display:block;
}
div, strong, span, a,
dl, dt, dd, ul, ol, li,
h1, h2, h3, h4, h5, h6, p, button, form, label{
  position:relative;
}
i{ font-style:normal; }
textarea.code{ display:block; width:100%; box-sizing:border-box; box-sizing:border-box; resize:vertical; border:1px solid #dddddd; background:#f5f6f8; padding:16px; }
/*폰트 사이즈 자동조절 방지*/
body{ -webkit-text-size-adjust:100%; }
strong{ font-weight:bold; }
var{ display:none; }
sup{ top:-0.25em; }
table, caption, tbody, tfoot, thead, tr, th, td{ position:static; }
th, td{ vertical-align:middle; }
/*안드로이드 탭색상제거*/
body{ -webkit-tap-highlight-color:rgba(0, 0, 0, 0); -webkit-higlight-color:rgba(0, 0, 0, 0) }
/*iOS 이미지 컨텍스트 메뉴 막기*/
img{ -webkit-touch-callout:none; }
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section{ display:block; }
ol, ul{ list-style:none; }
blockquote, q{ quotes:none; }
blockquote:before, blockquote:after,
q:before, q:after{ content:""; content:none; }
table{ border-collapse:collapse; border-spacing:0; }
a{ display:inline-block; cursor:pointer; text-decoration:none; color:inherit; max-width:100%; outline:none; }
a.line:hover{ text-decoration:underline; }
span.ib{ display:inline-block; }
input{ margin:0; padding:0; font-size:100%; }
input[type=checkbox],
input[type=file],
input[type=radio]{ cursor:pointer; }
label{ cursor:pointer; display:inline-block; }
button{ cursor:pointer; background:none; font-family:inherit; border:0; overflow:visible; padding:0; outline:none; }
button{ -webkit-appearance:button; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; }
input[type=text], input[type=search], input[type=password], textarea{ border-radius:0; border:1px solid #cccccc; outline:none; }
input[type=text], input[type=search], input[type=password], textarea, button{ -webkit-appearance:none; -moz-appearance:none; appearance:none; -webkit-border-radius:0; -moz-border-radius:0; -o-border-radius:0; }
input, textarea, select, button{ font-size:16px; line-height:1.5em; vertical-align:top; }
textarea{ box-sizing:border-box; border:1px solid #b5b5b5; }
/**[disabled]{ opacity:0.5; }*/
*[readonly]{ background:#f5f5f5; color:#999999; }
*[readonly]:focus{ outline:none !important; }
/* 텍스트 인풋에 자동완성 배경색 제거 */
input:-webkit-autofill{ -webkit-box-shadow:0 0 0 30px transparent inset; -webkit-text-fill-color:#ffffff; }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{ transition:background-color 5000s ease-in-out 0s; }
/*placeholder 색상변경*/
::-webkit-input-placeholder{ color:#666666 !important; }
::-moz-placeholder{ color:#666666 !important; }
:-ms-input-placeholder{ color:#666666 !important; }
:-moz-placeholder{ color:#666666 !important; }
caption, .blind, .hidden{ overflow:hidden; position:absolute; top:auto; left:-10000px; width:1px; height:1px; font-size:0; line-height:0; }
a, button{
  /*모바일에서*/
  /*- 클릭시 딜레이 방지*/
  -ms-touch-action:manipulation; /* IE10  */
  touch-action:manipulation; /* IE11+ */
  /*- 탭하이라이트 제거*/
  -webkit-tap-highlight-color:transparent;
}
.hidden{ visibility:hidden; }
.noselect{ -webkit-touch-callout:none; -webkit-user-select:none; -khtml-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; }
.pointer{ cursor:pointer; }
.float_clear:after{ content:""; display:block; clear:both; }
.float_left{ float:left; }
.float_right{ float:right; }
.txtl{ text-align:left !important; }
.txtc{ text-align:center !important; }
.txtr{ text-align:right !important; }
.block{ width:100%; }
.ds_block{ display:block !important; }
.noscroll{ overflow:hidden !important; }
.hide_scroll{ -ms-overflow-style:none; }
.hide_scroll::-webkit-scrollbar{ display:none; }
a[href^=mailto]{ color:#4d81d7; letter-spacing:0; }
a[href^=mailto]:hover{ text-decoration:underline; }
a[href="javascript:void(0);"]{ cursor:default; }
a[href="javascript:void(1);"]{ cursor:pointer; }
a.link{ color:#4d81d7; letter-spacing:0; }
a.link:hover{ text-decoration:underline; }
/**/
.noitem{ background:#eeeeee; color:#666666; letter-spacing:-0.1em; font-size:16px; }
.noitem{ outline:1px solid #dddee0; box-shadow:0px 0px 10px #dddee0; }
.noitem{ width:100%; }
.noitem{ display:table; table-layout:fixed; border-collapse:collapse; width:100%; }
.noitem > span{ display:table-cell; vertical-align:middle; text-align:center; }
/**/
.appearance_none{ -webkit-appearance:none; -moz-appearance:none; appearance:none; }
.appearance_none::-ms-expand{ display:none; }
/**/
.hover_btn > img{ display:none; }
.hover_btn > img:first-child{ display:block; }
.hover_btn > img:last-child{ display:none; }
.hover_btn:hover > img:first-child{ display:none; }
.hover_btn:hover > img:last-child{ display:block; }
/**/
.trigger{ display:none !important; }
/**/
[v-cloak]{ opacity:0; }



