/**
* src/assets/scss/var.scss
* 모든 SCSS 파일에서 사용할 변수에 대한 정의만 작성(이 파일은 vue.config.js 또는 webpack.config.js에 적용함 )
*/
$BG_COLOR_0:#111111;

$COLOR_0:#acfc02;

$N_COLOR_0:#000000;
$N_COLOR_1:#111111;
$N_COLOR_2:#1d1d1d;
$N_COLOR_3:#282828;
$N_COLOR_4:#3e3e3e;
$N_COLOR_5:#5b5b5b;
$N_COLOR_6:#9e9e9e;
$N_COLOR_7:#cacaca;
$N_COLOR_8:#ffffff;

$WARN_COLOR_0:#fcb602;

/******************************************
	Literal
*******************************************/
$HEADER_HEI:76px;
$FOOTER_HEI:110px;
$MAIN_TOP_PADDING:130px;
$MAIN_BOTTOM_PADDING:170px;
$MAIN_SIDE_PADDING:50px;
$MAX_WIDTH:1920px;
$WIDTH:1280px;
$MIN_WIDTH:1260px;
$MIN_HEIGHT:680px;