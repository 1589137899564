
        @import "@/assets/scss/_var.scss";
        @import "@/assets/scss/_mixin.scss";
        @import "@/assets/scss/_function.scss";
        

.goto_career_btn{
  position:fixed; z-index:100; bottom:40px; right:22px;
  transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
  opacity:1;
  
  > div{
    display:flex; justify-content:center; align-items:center;
    width:100px; height:100px; border-radius:50px;
    background-color:$COLOR_0; color:$N_COLOR_0;
    font-weight:800; font-size:18px; line-height:21.5px; text-align:center; letter-spacing:-0.5px;
    
  }
}

.goto_career_btn.hide{
  opacity:0;
}
