/*
 * src/assets/scss/layer_popup.scss
 */
/* FullLayer_0 [2022-07-09(009)] */
#FullLayer_0{
  .popup{
    > .head{
      height:61px; z-index:21;
  
      > .close_btn{
        right:calc(50% - 300px + 17px);
        bottom:-20px;
        transform:translateY(100%);
      }
    }
  
    .body{
      padding-left:$MAIN_SIDE_PADDING; padding-right:$MAIN_SIDE_PADDING;
      top:61px; padding-bottom:62px; height:calc(100% - 61px);
    
      > .creator_body{
        width:600px; margin-left:auto; margin-right:auto; background-color:$N_COLOR_0;
      
        > .SwiperCustom{
          //@include full_width_section;
        }
      }
    }
  }
  
  .info_section{
    padding-left:40px; padding-right:40px; padding-bottom:60px;
    margin-top:30px;
    
    > .area0{
      > .text0{
        font-weight:600; font-size:48px; line-height:58px;
      }
    }
    
    > .area1{
      margin-top:20px;
      font-size:20px; line-height:30px; color:#cacaca;
    }
    
  }
}

/* // FullLayer_0 [2022-07-09(009)] */

/* FullLayer_1 [2022-07-11(011)] */
#FullLayer_1{
  > .popup{
    > .body{
      
      padding-top:40px;
      padding-left:$MAIN_SIDE_PADDING; padding-right:$MAIN_SIDE_PADDING;
      font-size:20px; font-weight:500; line-height:30px; color:#CACACA;
      
      > .inner{
        width:1280px; margin-left:auto; margin-right:auto;
        padding-left:$MAIN_SIDE_PADDING; padding-right:$MAIN_SIDE_PADDING;
        
        > .main_title{
          font-weight:600; font-size:48px; line-height:68px; margin-bottom:40px;
          color:white;
        }
  
        > section{
          + section{
            margin-top:24px;
          }
        }
  
        .tit_0{
          color:white;
        }
      }
    }
  }
}

/* // FullLayer_1 [2022-07-11(011)] */
